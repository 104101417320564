import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, tokens } from '@fluentui/react-components'
import React, { useContext, useEffect, useState } from 'react'
import { questionColumns } from '../../helpers/columnHelper'
import { useTranslation } from 'react-i18next'
import { Question, QuestionStatus } from '../../api/schemas/schema'
import { QuestionRow } from './QuestionRow'
import { GetQuestions } from '../../api/question'
import { Loader } from '../../components/common/spinner/Loader'
import { handleMessage } from '../../helpers/stateHelper'
import { DataContext } from '../../App'

export const QuestionTable = () => {
   const [allQuestions, setAllQuestions] = useState<Question[]>(null)
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)

   useEffect(() => {
      ;(async () => {
         try {
            //TODO paginera frågehämtningen
            const questions = await GetQuestions()
            questions.filter((x) => x.status !== QuestionStatus.Archived)
            setAllQuestions(questions)
         } catch (error) {
            setAllQuestions([])
            handleMessage(dataContext.setRootState, 'error', t('FetchFailed'), t('CouldNotFetchQuestions'))
         }
      })()
   })
   return (
      <Table aria-label="Question table" as="table">
         <TableHeader as="thead">
            <TableRow as="tr">
               {questionColumns(t).map((column) => (
                  <TableHeaderCell as="th" style={{ width: column.width, fontWeight: tokens.fontWeightBold }} key={column.columnId}>
                     {column.label}
                  </TableHeaderCell>
               ))}
            </TableRow>
         </TableHeader>
         {!allQuestions ? (
            <Loader />
         ) : (
            <TableBody as="tbody">
               {allQuestions
                  .filter((q) => q.status !== QuestionStatus.Archived)
                  .map((question) => {
                     return <QuestionRow question={question} />
                  })}
            </TableBody>
         )}
      </Table>
   )
}
