import * as React from 'react'
import { Card, Field, makeStyles, SearchBox, tokens, Text } from '@fluentui/react-components'
import type { ComboboxProps } from '@fluentui/react-components'
import { useContext, useEffect, useState } from 'react'
import { searchDemands, targetDemandLevel } from '../../../helpers/optionsHelper'
import { DataContext } from '../../../App'
import { levelBadgeColor } from '../../../helpers/demandHelper'
import { Loader } from '../spinner/Loader'
import { t } from 'i18next'
import { Demand, DemandSearchResultDTO } from '../../../api/schemas/schema'

const useStyles = makeStyles({
   ul: {
      listStyleType: 'none',
      paddingLeft: 0,
      margin: 0,
   },
   li: {
      display: 'block',
      cursor: 'pointer',
      padding: tokens.spacingHorizontalM,
   },
   liHover: {
      ':hover': {
         backgroundColor: tokens.colorNeutralBackground2Hover,
      },
   },
   liContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   brand: { backgroundColor: tokens.colorBrandBackground, color: tokens.colorNeutralBackground1Pressed },
   gold: { backgroundColor: tokens.colorPaletteGoldBackground2, color: tokens.colorNeutralForeground1 },
   searchBox: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '100%',
   },
   card: {
      maxWidth: '100%',
      maxHeight: '50vh',
      padding: 0,
      overflowY: 'auto',
   },
})
type SearchableComboboxProps = {
   targetDemand: Demand
   placeholder: string
   setTargetDemand: React.Dispatch<React.SetStateAction<Demand>>
}
export const SearchableCombobox = ({ targetDemand, placeholder, setTargetDemand }: SearchableComboboxProps, props: Partial<ComboboxProps>): JSX.Element => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { isLoading } = dataContext.state
   const [searchValue, setSearchValue] = useState<string>('')
   const [searchResult, setSearchResult] = useState<DemandSearchResultDTO[]>([])

   useEffect(() => {
      if (searchValue.trim() === '') {
         setSearchResult([])
      } else {
         searchDemands(searchValue.trim(), setSearchResult, dataContext)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [searchValue])

   const demandIsSelected = (targetId: string, resultId: string) => {
      return targetId === resultId
   }

   return (
      <Field label={targetDemandLevel(dataContext) === 1 ? t('SearchForLevelOneDemands') : t('SearchForLevelTwoDemands')}>
         <SearchBox placeholder={placeholder} onChange={(e, data) => setSearchValue(data.value)} className={classes.searchBox} />
         {searchValue !== '' && searchResult.length < 1 ? (
            <li className={`${classes.li} `}>
               <div className={classes.liContainer}>
                  <div>
                     <Text as="p" weight="bold">
                        {t('EmptySearchResult')}
                     </Text>
                  </div>
               </div>
            </li>
         ) : searchResult.length > 0 ? (
            <Card size="small" className={classes.card}>
               <ul className={classes.ul}>
                  {searchResult.map((result) => (
                     <li
                        key={result.id}
                        className={`${classes.li} ${
                           targetDemand && demandIsSelected(targetDemand?.id, result?.id) ? levelBadgeColor(targetDemand.level, classes) : classes.liHover
                        }`}
                        onClick={() => {
                           if (demandIsSelected(targetDemand?.id, result?.id)) {
                              setTargetDemand(undefined)
                           } else {
                              setTargetDemand(result)
                           }
                        }}
                     >
                        <div className={classes.liContainer}>
                           <div>
                              <Text as="p" weight="bold">
                                 {result.designation}
                              </Text>
                              <br />
                              <Text>
                                 {result.publicId} - {result.urlText}
                              </Text>
                           </div>
                           <div>{isLoading && demandIsSelected(targetDemand?.id, result?.id) && <Loader isCustom />}</div>
                        </div>
                     </li>
                  ))}
               </ul>
            </Card>
         ) : null}
      </Field>
   )
}
