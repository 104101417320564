import { Field, makeStyles, tokens } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextStyle } from '@tiptap/extension-text-style'
import { RTFOutput } from '../outputs/RTFOutput'
import { EditorProvider } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import { MenuBar } from './MenuBar'
import './reset.css'
import { useEffect, useRef } from 'react'
type ControlledRTFInputProps = {
   name: any
   control: Control<any>
   label: string
   isDisabled?: boolean
   required?: boolean
   watchedValue: string
}
const useStyles = makeStyles({
   rtfContainer: {
      border: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
      backgroundColor: tokens.colorNeutralBackground1,
      borderRadius: tokens.borderRadiusMedium,
      fontSize: tokens.fontSizeBase300,
      borderBottomColor: tokens.colorNeutralStrokeAccessible,
   },
   editor: { padding: `0 ${tokens.spacingHorizontalL}`, minHeight: '200px' },
})

export const ControlledRTFInput = ({ name, control, label, isDisabled, required, watchedValue }: ControlledRTFInputProps) => {
   const classes = useStyles()
   const { t } = useTranslation()
   const extensions = [StarterKit, TextStyle, Underline, Link]
   const editorRef = useRef(null)

   useEffect(() => {
      if (editorRef.current && editorRef.current.getHTML() !== watchedValue) {
         editorRef.current.commands.setContent(watchedValue) // Set content when watchedValue changes
      }
   }, [watchedValue])
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange } }) => (
            <Field label={label} required={required}>
               {isDisabled ? (
                  <RTFOutput text={value} />
               ) : (
                  <div className={classes.rtfContainer}>
                     <EditorProvider
                        editorProps={{ attributes: { class: classes.editor } }}
                        slotBefore={<MenuBar />}
                        extensions={extensions}
                        content={value}
                        onCreate={({ editor }) => {
                           editorRef.current = editor // Save editor instance to ref
                        }}
                        onUpdate={(props) => onChange(props.editor.getHTML())}
                     />
                  </div>
               )}
            </Field>
         )}
      />
   )
}
