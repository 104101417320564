import { Demand, Question, QuestionDTO, QuestionStatus } from '../api/schemas/schema'
import { IDataContext } from '../interfaces/IDataContext'
import { UseFormReset, UseFormReturn } from 'react-hook-form'
import { TFunction } from 'i18next'
import { AddQuestion, DeleteQuestion, UpdateQuestion } from '../api/question'
import { UpdateItemInCollection } from './stateHelper'
export type QuestionFormValues = {
   selectedQuestion: QuestionDTO
   questions: QuestionDTO[]
}
export const saveQuestion = async (
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   dataContext: IDataContext,
   data: QuestionFormValues,
   reset: UseFormReset<QuestionFormValues>,
   t: TFunction<['translation', ...string[]], undefined>,
   demand: Demand,
   lvlOneParentDemandId: string
): Promise<void> => {
   try {
      setIsSubmitting(true)

      const res = await UpdateQuestion(data.selectedQuestion)

      const selectedQuestions = data.questions
      selectedQuestions[data.questions.findIndex((x) => x.order === res.order)] = res

      reset({ questions: selectedQuestions, selectedQuestion: res })
      updateQuestionsInState(dataContext, selectedQuestions, demand, lvlOneParentDemandId)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), t('QuestionSaved'))
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const getNewQuestion = (name: string, parentId: string): Question => ({
   modifiedBy: name,
   modifiedDate: new Date().toISOString(),
   backgroundInformation: '',
   order: 0,
   parentId,
   text: '',
   proposedAction: '',
   status: QuestionStatus.Ongoing,
   additionalModules: [],
   alternativeProcesses: [],
   answerOptions: [],
   leadershipPerspectives: [],
   organisationSizes: [],
   organisationTypes: [],
   process: undefined,
   snIs: [],
   subjects: [],
   subscriptions: [{ subscriptionId: 'B3CA142D-58B8-4E78-82E9-25132C0A88E7' }],
   answerType: null,
})

export const deleteQuestion = async (
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   dataContext: IDataContext,
   data: QuestionFormValues,
   reset: UseFormReset<QuestionFormValues>,
   t: TFunction<['translation', ...string[]], undefined>,
   demand: Demand,
   lvlOneParentDemandId: string
): Promise<void> => {
   try {
      setIsSubmitting(true)
      if (data.selectedQuestion.id) {
         await DeleteQuestion(data.selectedQuestion.id)
      }
      const filteredQuestions = data.questions.filter((x) => x.id !== data.selectedQuestion.id)

      reset((prev) => {
         const updatedSelectedQuestionIndex = filteredQuestions.findIndex((x) => x.id === data.selectedQuestion.id)
         const updatedSelectedQuestion =
            updatedSelectedQuestionIndex >= 0
               ? filteredQuestions[updatedSelectedQuestionIndex]
               : filteredQuestions.length === 0
               ? null
               : filteredQuestions[filteredQuestions.length - 1]
         return {
            questions: filteredQuestions,
            selectedQuestion: updatedSelectedQuestion,
         }
      })
      updateQuestionsInState(dataContext, filteredQuestions, demand, lvlOneParentDemandId)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ItemDeleted'), t('QuestionDeleted'))
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addQuestion = async (
   dataContext: IDataContext,
   questionForm: UseFormReturn<QuestionFormValues, any, undefined>,
   setOpenModal: React.Dispatch<React.SetStateAction<'new' | 'undo' | 'badgeUndo'>>,
   name: string,
   demand: Demand,
   lvlOneParentDemandId: string
): Promise<void> => {
   const { reset, getValues } = questionForm
   const newQuestion = await AddQuestion(getNewQuestion(name, demand.id))
   const questions = [...getValues().questions.sort((a, b) => a.order - b.order), newQuestion]
   reset((prev) => ({
      selectedQuestion: newQuestion,
      questions,
   }))
   updateQuestionsInState(dataContext, questions, demand, lvlOneParentDemandId)
   setOpenModal(null)
}

export const getMatchinQuestions = (questions: Question[], question: Question, parentDemand: Demand): Question[] => {
   const hasMatching = (types1: string[], types2: string[]): boolean => {
      return types1.some((type1) => types2.some((type2) => type1 === type2))
   }
   if (parentDemand.level === 3) return []

   return questions
      .filter((x) => x.id !== question.id)
      .filter((existingQuestion) => {
         return (
            hasMatching(
               existingQuestion.organisationTypes.map((x) => x.organisationTypeId),
               question.organisationTypes.map((x) => x.organisationTypeId)
            ) &&
            hasMatching(
               existingQuestion.organisationSizes.map((x) => x.organisationSizeId),
               question.organisationSizes.map((x) => x.organisationSizeId)
            ) &&
            hasMatching(
               existingQuestion.snIs.map((x) => x.sniId),
               question.snIs.map((x) => x.sniId)
            )
         )
      })
}
const updateQuestionsInState = (dataContext: IDataContext, updatedQuestions: Question[], demand: Demand, grandparentId: string) => {
   const { demands } = dataContext.state
   const updatedLvlOneParentDemand = demands.find((x) => x.id === grandparentId)

   const setQuestions = (target: Demand) => (target.questions = updatedQuestions)

   if (demand.level === 1) {
      updatedLvlOneParentDemand.questions = updatedQuestions
   } else if (demand.level === 2) {
      const child = updatedLvlOneParentDemand.children.find((x) => x.id === demand.id)
      setQuestions(child)
   } else {
      const child = updatedLvlOneParentDemand.children.find((x) => x.id === demand.parentId)
      const grandChild = child.children.find((x) => x.id === demand.id)
      setQuestions(grandChild)
   }

   UpdateItemInCollection(dataContext.setRootState, demands, updatedLvlOneParentDemand, 'demandsLvlOne')
}
