// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TFunction } from 'i18next'
import { IDataContext } from '../interfaces/IDataContext'
import { Api, DemandSearchResultDTO } from '../api/schemas/schema'
const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export const searchDemands = async (
   searchValue: string,
   setSearchResult: React.Dispatch<React.SetStateAction<DemandSearchResultDTO[]>>,
   dataContext: IDataContext
) => {
   const result = (await apiInstance.api.demandSearchList({ searchText: searchValue, demandLevel: targetDemandLevel(dataContext) })).data
   if (result.length > 0) {
      result.sort((a: any, b: any) => a.publicId - b.publicId)
      setSearchResult(result)
   } else {
      setSearchResult([])
   }
}

export const targetDemandLevel = (dataContext: IDataContext): 2 | 1 => {
   const { selectedDemands } = dataContext.state
   const targetIsLvlOne = selectedDemands.some((demand) => demand.level === 2)
   return targetIsLvlOne ? 1 : 2
}
