import React, { useEffect, useState } from 'react'
import { ExportStatus } from '../../helpers/excelHelper'
import { useTranslation } from 'react-i18next'
import { Modal } from '../../components/common/modal/Modal'
import { makeStyles, Spinner } from '@fluentui/react-components'

const useStyles = makeStyles({
   dialog: {
      minHeight: '100px',
   },
})

type DialogExportList = {
   exportingStatus: ExportStatus
}
export const DialogExportingList = ({ exportingStatus }: DialogExportList) => {
   const { t } = useTranslation()
   const classes = useStyles()
   const [message, setMessage] = useState<string>(t('ThisMightTakeAFewMinutes'))

   useEffect(() => {
      if (exportingStatus !== null) {
         const interval = setInterval(() => {
            setMessage((prevMessage) =>
               prevMessage === t('ThisMightTakeAFewMinutes')
                  ? exportingStatus === ExportStatus.FETCHING_DEMANDS
                     ? t('FetchingDemands')
                     : exportingStatus === ExportStatus.FETCHING_QUESTIONS
                     ? t('FetchingQuestions')
                     : exportingStatus === ExportStatus.GENERATING_EXCEL
                     ? t('GeneratingExcel')
                     : ''
                  : t('ThisMightTakeAFewMinutes')
            )
         }, 3000)
         return () => clearInterval(interval)
      }
   }, [exportingStatus, t])

   return (
      <Modal open={exportingStatus !== null} title={t('ExportingDemandsAndQuestions')} contentClassname={classes.dialog}>
         <>
            {message}...
            <Spinner />
         </>
      </Modal>
   )
}
