export const en = {
   AppHeader: '365 Back Office',

   Title: 'Title',
   Status: 'Status',
   ID: 'ID',

   SfsNo: 'SFS No.',
   Department: 'Department/Authority',
   IssuedAt: 'Issued at',
   ModifiedAt: 'ModifiedAt',
   ModificationRegister: 'Modification register',
   Source: 'Source',
   LawsToValidate: 'Process laws to decide whether or not they shall be a demand in 365 Robust',
   Content: 'Content',

   ShowLawsWithStatusNo: 'Show laws with status "No"',
   Number: 'Number',
   Subject: 'Subject',
   LeadershipPerspective: 'Management perspective',
   NumberOfLaws: 'Number of laws',
   Modified: 'Modified',
   ModifiedBy: 'Modified by',
   NewDemand: 'New demand',
   ChoosenDemands: 'Choosen demands',
   ChoosenDemandsInfo:
      'These are the choosen and created demands. These are the ones that are visible in 365 Robust. You can add more laws in the "Laws" section',
   WebLink: 'Link',
   Description: 'Description',
   ChooseParagraphsInfo: 'Choose the paragraphs that shall be demands',
   ClearDemand: 'Clear ongoing demand handling',
   ClearDemandText: 'Are you sure you want to clear the ongoing demand handling? This will delete the data and resets the status. It is irreverisble',
   LawWithStatusNoCantBeHandled: "A law with status 'no' can't be handled.",
   ChangeStatusToHandleLaw: 'To handle the law, status must be changed to "yes" or "maybe"',
   QuestionWillBeDeletedOnContinue:
      'This question will be deleted if you continue. Please save the question before you switch question if you want to save the question',

   SignIn: 'Sign in',
   SignOut: 'Sign out',
   WelcomeTo: 'Welcome to',
   SystemSettings: 'System settings',

   // Nav
   Dashboard: 'Dashboard',
   Home: 'Home',
   CustomerRegister: 'Customer register',
   DemandQuestionList: 'List of demands and questions',
   LawsImport: 'Import of laws',
   KnowledgeBankFormCustomer: 'Knowledge bank for customer',
   SomethingWentWrong: 'Something went wrong',
   CouldntFetchDashboardData: "Couldn't fetch dashboard data",

   // Status
   Yes: 'Yes',
   No: 'No',
   Maybe: 'Maybe',
   Demand: 'Demand',
   OngoingDemand: 'Ongoing demand',
   Updated: 'Updated',
   Revoked: 'Revoked',
   New: 'New',
   All: 'All',
   Empty: '(Empty)',
   Definite: 'Definite',
   Ongoing: 'Ongoing',
   Reviewing: 'Reviewing',
   Resting: 'Resting',
   Archived: 'Archived',
   SavedButNotSure: '',
   Archive: 'Archive',

   // Demand
   SubType: 'Sub type',
   EUDirective: 'EU directive',
   Law: 'Law',
   Paragraph: 'Paragraph',
   ISOStandard: 'ISO standard',
   Ordinance: 'Ordinance', // Förordning
   Regulation: 'Regulation', // Föreskrift
   GeneralAdvice: 'General advice',
   Guidance: 'Guidance',
   IndustryDemand: 'Industry demand',
   InternalDemand: 'Internal demand',
   DemandType: 'Type of demand',
   Origin: 'Origin',
   Sweden: 'Sweden',
   DemandLevel: 'Demand level',
   PublicAuthorityDemand: 'Public authority demand',
   InsuranceDemand: 'Insurance demand',
   OtherDemand: 'Other demand',
   DemandArea: 'Demand area',
   Economy: 'Economy',
   Sustainability: 'Sustainability',
   Quality: 'Quality',
   Environment: 'Environment',
   Staff: 'Staff',
   Security: 'Security',
   StatusImport: 'Import status',
   Question: 'Question',
   DemandText: 'Demand text',
   AnswerType: 'Type of answer',
   DemandSubType: 'Demand subtype',
   DemandSource: 'Demand source',
   AllDemands: 'All demands',
   UrlText: 'Url text',
   NewSubDemand: 'New subdemand',
   ParentDemand: 'Parent demand',
   DemandArchiveWarningText: 'Are you sure you want to archive this demand with underlying information?',
   DemandDeleteWarningText: 'Are you sure you want to delete this demand?',
   ArchivedDemands: 'Archived demands',
   Activate: 'Activate',
   DemandActivateWarningText: 'Are you sure you want to activate this demand with underlying information again?',
   ActiveDemands: 'Active demands',
   ActiveQuestions: 'Active questions',
   ImportedDemands: 'Imported demands',
   Version: 'Version',
   NoArchivedDemands: 'There are no archived demands',
   EnterURL: 'Enter URL',

   // Status
   Warning: 'Warning',
   SendToDemandList: 'Send to list of demands',
   Save: 'Save',
   Cancel: 'Cancel',
   Clear: 'Clear',
   Undo: 'Undo',
   Delete: 'Delete',
   SavedSuccess: 'Save was successful',
   SavedError: 'Could not save',
   SavedDemands: 'Demands were properly saved',
   DemandsSent: 'Demands were successfully sent to the list of demands',
   DemandMoveError: 'Failed to move demands',
   QuestionSaved: 'Question was successfully saved',
   DemandSuccessfullyCleared: 'The ongoing demand handling was successfully cleared',
   DemandClearingFailed: 'Something went wrong when the ongoing claim handling was to be cleared and reset',
   RequiredField: 'This field is required',
   UndoDemandChanges: 'Do you want to undo your changes on this demand?',
   LatestChangesWillBeUndone: 'Changes will be reset.',
   SaveBeforeProceed: 'Save your changes before proceeding',
   FormHasUnsavedChanges: 'The form has unsaved changes. Please save them before proceeding',
   AreYouSureLatestChangesWillBeUndone:
      'Are you sure you want to continue? Your latest changes will be reset. If you want to save your changes, click cancel and save the form before proceeding',
   UndoQuestionChanges: 'Vill du ångra dina ändringar i denna fråga?',
   UndoChanges: 'Undo changes',
   Continue: 'Continue',
   FetchFailed: 'Fetch failed',
   ItemDeleted: 'Item was successfully deleted',
   DeleteError: 'Item could not be deleted',
   DeleteAttachment: 'Delete attachment',
   CantChangeParentByDND: 'You can not change the parent of the item by drag and drop',
   CantDNDOnArchived: 'You can not change the order of archived items',
   CantChangeParentOrLevelByDND: 'You can not change the parent or level of the item by drag and drop',
   ActionNotSupported: 'Action not supported',
   CouldNotFetchQuestions: 'Something went wrong when fetching questions',
   CouldNotFetchLaw: 'Something went wrong when fetching the law',
   QuestionDeleted: 'Question is deleted',

   // Question
   QuestionsForDemand: 'Questions for demand',
   NewQuestion: 'New question',
   BackgroundInformation: 'Background information',
   ProposedAction: 'Proposed action',
   OrganisationType: 'Organisation type',
   OrganisationSize: 'Organisation size',
   Process: 'Process',
   AlternativeProcesses: 'Alternative processes',
   SNI: 'SNI',
   AdditionalModules: 'Additional modules',
   Subscription: 'Subscription',
   Attachement: 'Attachement',
   AddFile: 'Add file',
   NoQuestionsCreated: 'There are no questions connected to this demand',
   NoQuestionChosen: 'Choose a question to view the content',
   DeleteAttachmentText: 'Are you sure you want to delete attachment?',
   QuestionWithCombinationAlreadyExists: 'A question with a combination of these values already exists.',
   SeeQuestion: 'See question number',
   LoadingQuestions: 'Loading questions',

   //AnswerOptions
   YesNo: 'Yes/No',
   YesNoDontKnow: 'Yes/No/Dont know',
   ComplianceSixAnswers: 'Compliance 6 answers',
   ComplianceFourAnswers: 'Compliance 4 answers',
   OwnAnswers: 'Own answers',
   AddAnswerOption: 'Add answer option',
   AnswerOption: 'Answer option',
   Info: 'Info',
   Value: 'Value',
   DemandConnection: 'Demand connection',
   UndoAnswerOptionChanges: 'Do you want to undo your changes on this answer option?',
   Catalog: 'Catalog',
   AnswerOptionList: 'List for answer options',
   Order: 'Order',
   CreateNewAnswerOption: 'New answer option',
   ConnectDemandToAnswer: 'Should demand be able to be linked to this answer option?',
   SelectAnAnswerOption: 'Select an answer option',
   DeleteDemandConnection: 'Delete demand connection',
   ExportedSuccess: 'Successfully exported',
   ExportFail: 'Exporting failed',
   ExportingDemandsAndQuestions: 'Exporting demands and questions',
   ThisMightTakeAFewMinutes: 'This might take a few minutes',
   FetchingDemands: 'Fetching demands',
   FetchingQuestions: 'Fetching questions',
   GeneratingExcel: 'Generating excel',
   ChangedTo: 'Changed to',

   //Labels / Placeholders
   Search: 'Search',
   Designation: 'Designation',
   ExpandAll: 'Expand all',
   MiniMizeAll: 'Minimize',

   //Modal header / Modal content
   MoveDemand: 'Move demand',
   MoveTargetTo: 'Move to...',
   DemandModalSearchParams: 'Search for ID, web text or designation',
   EmptySearchResult: 'No demands matching the serach critera...',
   SearchForLevelOneDemands: 'Search for level 1 demands',
   SearchForLevelTwoDemands: 'Search for level 2 demands',

   //Buttons
   FastMoveDemand: 'Move demand',
   AbortFastMove: 'Abort',
   MoveTo: 'Target',
   Move: 'Move',
   ExportDemandsAndQuestionsToExcel: 'Export to excel',

   // Dashboard
   Welcome: 'Welcome',
   OngoingActivities: 'Ongoing activitetes',
   Activity: 'Activity',
   StatusLaws: 'Status on laws',
   LatestUpdate: 'Latest update',
   NumberOfCustomers: 'Number of customers',
   NumberOfQuestions: 'Number of questions',
   new: 'new',
   revoked: 'revoked',
   updated: 'updated',
   Changed: 'Changed',
}
