import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Field, Dropdown, Option, tokens, OptionGroup } from '@fluentui/react-components'
import { CheckboxChecked24Filled } from '@fluentui/react-icons'
type DropdownMultiProps = {
   name: any
   control: Control<any>
   label: string
   items: any
   questionId: string
   modelName: string
   disabled?: boolean
   disabledOption?: string
   validationState?: 'error'
   sortOnLocale?: boolean
}
export const DropdownMulti = ({
   name,
   control,
   label,
   items,
   questionId,
   modelName,
   disabled,
   disabledOption,
   validationState,
   sortOnLocale,
}: DropdownMultiProps): JSX.Element => {
   const itemCollection = sortOnLocale ? items.sort((x: any, b: any) => x.text.localeCompare(b.text)) : items
   const allExceptDisabled = items.filter((item: any) => item.text !== disabledOption)
   const handleSelectAll = (selectedOptions: string[], onChange: Function) => {
      if (selectedOptions.length < items.length) {
         onChange(
            allExceptDisabled.map((item: any) => ({
               [modelName + 'Id']: item.id,
               questionId: questionId,
               [modelName]: item,
            }))
         )
      } else {
         onChange([])
      }
   }
   return (
      <Controller
         name={name}
         control={control}
         render={({ field: { value, onChange } }) => {
            return (
               <Field label={label} validationState={validationState}>
                  <Dropdown
                     multiselect
                     onOptionSelect={(e, data) => {
                        if (data.selectedOptions.includes('all')) {
                           handleSelectAll(data.selectedOptions, onChange)
                        } else {
                           onChange(
                              data.selectedOptions.map((option) => ({
                                 [modelName + 'Id']: option,
                                 questionId: questionId,
                                 [modelName]: items.find((x: any) => x.id === option),
                              }))
                           )
                        }
                     }}
                     selectedOptions={
                        value ? value.sort((a: any, b: any) => a[modelName].text.localeCompare(b[modelName].text)).map((x: any) => x?.[modelName + 'Id']) : []
                     }
                     style={{ minWidth: 'auto' }}
                     value={
                        value && value.length === allExceptDisabled.length
                           ? 'Alla'
                           : value && value.length < allExceptDisabled.length
                           ? value.map((x: any) => x[modelName]?.text).join(', ')
                           : ''
                     }
                     disabled={disabled}
                  >
                     <OptionGroup>
                        <Option text={'Alla'} key="all" value="all" style={{ border: 'none' }} checkIcon={value.length === allExceptDisabled.length && null}>
                           {value.length === allExceptDisabled.length && <CheckboxChecked24Filled color={tokens.colorBrandForeground1} />}
                           {'Alla'}
                        </Option>
                     </OptionGroup>
                     {itemCollection.map((x: any) => (
                        <Option key={x.id} value={x.id} disabled={x.text === disabledOption}>
                           {x.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            )
         }}
      />
   )
}
