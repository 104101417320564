import React from 'react'
import { Reorder } from 'framer-motion'
import { ReorderRowContent } from './ReorderDemandRowContent'
import { Demand } from '../../api/schemas/schema'
import { useContext } from 'react'
import { DataContext } from '../../App'
import { useTranslation } from 'react-i18next'
import { demandColumns } from '../../helpers/columnHelper'
import { handleDrop } from '../../helpers/dndHelper'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { UpdateItemInCollection } from '../../helpers/stateHelper'
const useStyles = makeStyles({
   row: {
      display: 'flex',
      ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2),
      ...shorthands.padding(tokens.spacingVerticalS, 0),
      '&:hover': {
         backgroundColor: tokens.colorNeutralBackground1Hover,
      },
      '&:active': {
         backgroundColor: tokens.colorNeutralBackground1Pressed,
      },
   },
})
type DemandRowProps = {
   demands: Demand[]
   demand: Demand
   loadingRows: string[]
   showButton: string
   expandAllDemands: boolean
   setShowButton: (value: React.SetStateAction<string>) => void
   toggleRowExpansion: (demandId: string) => void
   setDndParentDemands: React.Dispatch<React.SetStateAction<Demand[]>>
   dndParentDemands?: Demand[]
   parent?: Demand
}

export const DemandRow = ({
   demands,
   demand,
   loadingRows,
   showButton,
   expandAllDemands,
   setShowButton,
   toggleRowExpansion,
   setDndParentDemands,
   dndParentDemands,
   parent,
}: DemandRowProps): JSX.Element => {
   const classes = useStyles()
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const { expandedDemands } = dataContext.state
   const isExpanded = (demandId: string) => {
      return expandedDemands.some((expandedDemand) => expandedDemand === demandId)
   }
   return (
      <>
         <Reorder.Item
            onMouseEnter={() => setShowButton(demand.id)}
            onDragStart={() => {
               if (isExpanded(demand.id)) {
                  toggleRowExpansion(demand.id)
                  demand?.children.forEach((x) => isExpanded(x.id) && toggleRowExpansion(x.id))
               }
            }}
            onDragEnd={async () => {
               await handleDrop(dndParentDemands?.length > 0 ? dndParentDemands : demands, demand, parent, setDndParentDemands, dataContext)
            }}
            as="div"
            role="row"
            key={demand.id}
            value={demand}
         >
            <ReorderRowContent
               toggleRowExpansion={toggleRowExpansion}
               item={demand}
               parent={parent}
               expanded={isExpanded(demand.id) ?? false}
               columns={demandColumns(t)}
               rowClass={classes.row}
               setShowButton={setShowButton}
               showButton={showButton}
               expanding={loadingRows.some((x) => x === demand.id)}
               items={demands}
               expandAllDemands={expandAllDemands}
            />
         </Reorder.Item>
         {isExpanded(demand.id) && (
            <Reorder.Group
               layoutScroll
               as="div"
               role="rowgroup"
               axis="y"
               values={demand.children}
               onReorder={(children) => {
                  let parentDemand = demand
                  if (parentDemand.level === 1) {
                     parentDemand.children = children
                     UpdateItemInCollection(dataContext.setRootState, demands, parentDemand, 'demands')
                  }

                  if (parentDemand.level === 2) {
                     parentDemand.children = children
                     const grandParentDemand = demands.find((parent) => parent.id === parentDemand.parentId)
                     const demandIndex = grandParentDemand.children.findIndex((x) => x.id === parentDemand.id)
                     grandParentDemand.children[demandIndex] = parentDemand

                     UpdateItemInCollection(dataContext.setRootState, demands, grandParentDemand, 'demands')
                  }
               }}
            >
               {isExpanded(demand.id) &&
                  demand.children.map((child) => (
                     <DemandRow
                        key={child.id}
                        demands={demands}
                        demand={child}
                        loadingRows={loadingRows}
                        showButton={showButton}
                        expandAllDemands={expandAllDemands}
                        setShowButton={setShowButton}
                        setDndParentDemands={setDndParentDemands}
                        toggleRowExpansion={toggleRowExpansion}
                        parent={demand}
                     />
                  ))}
            </Reorder.Group>
         )}
      </>
   )
}
