import React, { useContext, useEffect, useState } from 'react'
import { TableColumnDefinition, makeStyles, Option, Field, Dropdown, Text } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { List } from '../components/common/list/List'
import { Page } from './Page'
import { useSearchParams } from 'react-router-dom'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { GetFilteredLaws, GetLaws, NumberOfLaws } from '../api/law'
import { DataContext } from '../App'
import { setLoading } from '../helpers/stateHelper'
import { useMsal } from '@azure/msal-react'
import { enumValuesToOptions, enumValuesToTranslate } from '../helpers/enumHelper'
import { getLawColumns } from '../helpers/lawHelper'
import { Law, LawStatus, StatusImport } from '../api/schemas/schema'
import { Title } from '../components/common/texts/Title'

const useStyles = makeStyles({
   alignEnd: {
      alignSelf: 'end',
   },
})
export const Laws = () => {
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const { numberOfPages, filteredPages, allLawsCount } = dataContext.state
   const { setRootState } = dataContext
   const classes = useStyles()
   const [laws, setLaws] = useState<Law[]>([])
   const [columns, setColumns] = useState<TableColumnDefinition<Law>[]>([])
   const [statusFilter, setStatusFilter] = useState<string[]>([])
   const [importStatusFilter, setImportStatusFilter] = useState<string[]>([])
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [searchParams, setSearchParams] = useSearchParams()
   const [numberOfLaws, setNumberOfLaws] = useState<number>(0)
   const page = parseInt(searchParams.get('page'))
   const { accounts } = useMsal()

   useEffect(() => {
      setColumns(getLawColumns(setRootState, accounts[0], setLaws))
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   useEffect(() => {
      if (statusFilter.length === 0 && importStatusFilter.length === 0) setNumberOfLaws(allLawsCount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [allLawsCount])
   useEffect(() => {
      if (!searchParams.get('page')) setSearchParams({ page: '1' })
      else if (parseInt(searchParams.get('page')) > numberOfPages) setSearchParams({ page: '105' })
   }, [numberOfPages, page, searchParams, setSearchParams])
   useEffect(() => {
      if (typeof page === 'number' && !isNaN(page) && page <= numberOfPages) {
         const fetchData = async () => {
            setLoading(setRootState, true)
            try {
               let laws
               if (statusFilter.length === 0 && importStatusFilter.length === 0) {
                  laws = await GetLaws(page)
                  if (allLawsCount > 0) {
                     setNumberOfLaws(allLawsCount)
                  }
                  setRootState((prev) => ({ ...prev, filteredPages: undefined }))
               } else {
                  let numberOfLaws = await NumberOfLaws(statusFilter, importStatusFilter)
                  setNumberOfLaws(numberOfLaws)

                  setRootState((prev) => ({ ...prev, filteredPages: Math.ceil(numberOfLaws / 50) }))
                  laws = await GetFilteredLaws(statusFilter, importStatusFilter, page)
               }
               setLaws(laws)
            } catch (error) {
            } finally {
               setLoading(setRootState, false)
            }
         }
         fetchData()
      }
   }, [allLawsCount, numberOfPages, page, setRootState, statusFilter, importStatusFilter])
   const handleStatusOptionSelect = (event: any, data: any) => {
      setStatusFilter(data.selectedOptions)

      setSearchParams((params) => {
         params.set('page', '1')
         return params
      })
   }
   const handleImportStatusOptionSelect = (event: any, data: any) => {
      setImportStatusFilter(data.selectedOptions)

      setSearchParams((params) => {
         params.set('page', '1')
         return params
      })
   }
   const enumStatusTranslation = (number: number): string => {
      if (number < 100) return enumValuesToTranslate(LawStatus, t, number)
      return enumValuesToTranslate(StatusImport, t, number)
   }
   return (
      <Page header={<Title as="h2">{t('LawsImport')}</Title>}>
         <Grid>
            <GridItem size="3/12">
               <Field label={t('Status')}>
                  <Dropdown
                     multiselect
                     onOptionSelect={handleStatusOptionSelect}
                     selectedOptions={statusFilter ?? statusFilter}
                     value={statusFilter ? statusFilter.map((x) => enumStatusTranslation(Number(x))).join(', ') : ''}
                  >
                     {enumValuesToOptions(LawStatus, t).map((option) => (
                        <Option key={option.key.toString()} value={option.key.toString()}>
                           {option.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            </GridItem>
            <GridItem size="3/12">
               <Field label={t('StatusImport')}>
                  <Dropdown
                     multiselect
                     onOptionSelect={handleImportStatusOptionSelect}
                     selectedOptions={importStatusFilter ?? importStatusFilter}
                     value={importStatusFilter ? importStatusFilter.map((x) => enumStatusTranslation(Number(x))).join(', ') : ''}
                  >
                     {enumValuesToOptions(StatusImport, t).map((option) => (
                        <Option key={option.key.toString()} value={option.key.toString()}>
                           {option.text}
                        </Option>
                     ))}
                  </Dropdown>
               </Field>
            </GridItem>
            <GridItem size="2/12" className={classes.alignEnd}>
               <Text>{`${t('NumberOfLaws')}: ${numberOfLaws}`}</Text>
            </GridItem>
            <GridItem size="12/12">
               <List
                  pagination={true}
                  numberOfPages={filteredPages || filteredPages === 0 ? filteredPages : numberOfPages}
                  columns={columns}
                  items={laws}
                  title={t('LawsImport')}
                  info={t('LawsToValidate')}
                  loading={dataContext.state.isLoading}
                  asCard
               />
            </GridItem>
         </Grid>
      </Page>
   )
}
