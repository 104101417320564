import React, { useContext } from 'react'
import {
   Card,
   CardHeader,
   Button,
   MenuItem,
   makeStyles,
   tokens,
   shorthands,
   mergeClasses,
   MessageBar,
   MessageBarBody,
   MessageBarTitle,
} from '@fluentui/react-components'
import { t } from 'i18next'
import { Demand, QuestionStatus } from '../../api/schemas/schema'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { QuestionFormValues, deleteQuestion, getMatchinQuestions } from '../../helpers/questionHelper'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { ControlledDropDown } from '../common/inputs/Dropdown'
import { DropdownMulti } from '../common/inputs/DropdownMulti'
import { FileInput } from '../common/inputs/FileInput'
import { SniDropdown } from '../common/inputs/SniDropdown'
import { ContextualMenu } from '../common/menu/Menu'
import { MoreHorizontal20Regular, Delete20Regular } from '@fluentui/react-icons'
import { DataContext } from '../../App'
import { UseFormReturn } from 'react-hook-form'
import { ProcessDropdown } from './ProcessDropdown'
import AnswerOptionInQuestion from './AnswerOptionInQuestion'
import { ControlledRTFInput } from '../common/inputs/ControlledRTFInput'

const useStyles = makeStyles({
   marginRight: {
      marginRight: tokens.spacingHorizontalXL,
   },
   greyCard: {
      backgroundColor: tokens.colorNeutralBackground4,
   },
   smallGap: {
      ...shorthands.gap(tokens.spacingHorizontalS),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingHorizontalS),
      },
   },
   flexColumn: {
      display: 'flex',
      flexDirection: 'column',
   },
   flexEnd: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
})
type QuestionCardProps = {
   questionForm: UseFormReturn<QuestionFormValues, any, undefined>
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
   isSubmitting: boolean
   setOpenModal: React.Dispatch<React.SetStateAction<'new' | 'badgeUndo' | 'undo'>>
   isDisabled: boolean
   parentDemand: Demand
   grandParentId: string
}
export const QuestionCard = ({ questionForm, setIsSubmitting, isSubmitting, setOpenModal, isDisabled, parentDemand, grandParentId }: QuestionCardProps) => {
   const dataContext = useContext(DataContext)
   const { subjects, leadershipPerspectives, organisationSizes, organisationTypes, snis, processes, additionalModules } = dataContext.state
   const classes = useStyles()
   const {
      control,
      reset,
      watch,
      formState: { isDirty },
   } = questionForm
   const existingMatchingQuestions = getMatchinQuestions(watch('questions'), watch('selectedQuestion'), parentDemand)
   const isQuestionValuesUnique = existingMatchingQuestions.length === 0
   return (
      <Card appearance="filled" className={classes.greyCard}>
         <CardHeader
            action={
               <ContextualMenu
                  menuTrigger={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />}
                  menuItems={
                     <MenuItem
                        icon={<Delete20Regular />}
                        onClick={() => deleteQuestion(setIsSubmitting, dataContext, watch(), reset, t, parentDemand, grandParentId)}
                     >
                        {t('Delete')}
                     </MenuItem>
                  }
               />
            }
         />
         {!isQuestionValuesUnique && (
            <MessageBar intent="error">
               <MessageBarBody>
                  <MessageBarTitle>{t('QuestionWithCombinationAlreadyExists')}</MessageBarTitle>
                  {t('SeeQuestion')} {existingMatchingQuestions.map((x) => x.order).join(', ')}
               </MessageBarBody>
            </MessageBar>
         )}
         <Grid>
            <GridItem className={mergeClasses(classes.flexColumn, classes.smallGap)} size="6/12">
               <ControlledRTFInput
                  name={'selectedQuestion.text'}
                  control={control}
                  label={t('Question')}
                  isDisabled={isDisabled || isSubmitting}
                  required
                  watchedValue={watch('selectedQuestion.text')}
               />
               <AnswerOptionInQuestion questionForm={questionForm} isDisabled={isDisabled || isSubmitting} />
               <ControlledRTFInput
                  name={'selectedQuestion.backgroundInformation'}
                  control={control}
                  label={t('BackgroundInformation')}
                  isDisabled={isDisabled || isSubmitting}
                  watchedValue={watch('selectedQuestion.backgroundInformation')}
               />
               <ControlledRTFInput
                  name={'selectedQuestion.proposedAction'}
                  control={control}
                  label={t('ProposedAction')}
                  isDisabled={isDisabled || isSubmitting}
                  watchedValue={watch('selectedQuestion.proposedAction')}
               />
            </GridItem>
            <GridItem size="6/12">
               <Grid className={classes.smallGap}>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.organisationSizes'}
                        control={control}
                        label={t('OrganisationSize')}
                        items={organisationSizes}
                        questionId={watch('selectedQuestion.id')}
                        modelName="organisationSize"
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.organisationTypes'}
                        control={control}
                        label={t('OrganisationType')}
                        items={organisationTypes}
                        questionId={watch('selectedQuestion.id')}
                        modelName="organisationType"
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <SniDropdown
                        questionForm={questionForm}
                        label={t('SNI')}
                        items={snis}
                        disabled={isDisabled || isSubmitting}
                        validationState={!isQuestionValuesUnique ? 'error' : null}
                     />
                  </GridItem>
                  <GridItem size="4/12">
                     <ProcessDropdown
                        control={control}
                        questionId={watch('selectedQuestion.id')}
                        alternativeProcessIds={watch('selectedQuestion.alternativeProcesses')?.map((ap) => ap.processId)}
                        disabled={isDisabled || isSubmitting}
                     />
                  </GridItem>
                  <GridItem size="8/12">
                     <DropdownMulti
                        control={control}
                        name={'selectedQuestion.alternativeProcesses'}
                        items={processes}
                        label={t('AlternativeProcesses')}
                        questionId={watch('selectedQuestion.id')}
                        modelName="process"
                        disabledOption={watch('selectedQuestion.process.process.text')}
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.leadershipPerspectives'}
                        control={control}
                        label={t('LeadershipPerspective')}
                        items={leadershipPerspectives}
                        questionId={watch('selectedQuestion.id')}
                        modelName="leadershipPerspective"
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.subjects'}
                        control={control}
                        label={t('Subject')}
                        items={subjects}
                        questionId={watch('selectedQuestion.id')}
                        modelName="subject"
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="12/12">
                     <DropdownMulti
                        name={'selectedQuestion.additionalModules'}
                        control={control}
                        label={t('AdditionalModules')}
                        items={additionalModules}
                        questionId={watch('selectedQuestion.id')}
                        modelName="additionalModule"
                        disabled={isDisabled || isSubmitting}
                        sortOnLocale
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <ControlledDropDown
                        control={control}
                        name={'selectedQuestion.status'}
                        options={enumValuesToOptions(QuestionStatus, t)}
                        label={t('Status')}
                        disabled={isDisabled || isSubmitting}
                     />
                  </GridItem>
                  <GridItem size="6/12">
                     <FileInput label={t('Attachement')} questionForm={questionForm} disabled={isDisabled || isSubmitting} />
                  </GridItem>

                  {/* UTKOMMENTERAT TILLSVIDARE KAN KOMMA ATT ANVÄNDAS
                  <GridItem size="12/12">
                        <DropdownMulti
                           name={'selectedQuestion.subscriptions'}
                           control={control}
                           label={t('Subscriptions')}
                           items={subscriptions}
                           questionId={watch('selectedQuestion.id')}
                           modelName="subscription"
                           sortOnLocale
                        />
                  </GridItem> */}
               </Grid>
            </GridItem>

            <GridItem size="12/12" className={classes.flexEnd}>
               <Button disabled={!isDirty || isSubmitting} onClick={() => setOpenModal('undo')} className={classes.marginRight}>
                  {t('Undo')}
               </Button>
               <Button disabled={!isDirty || isSubmitting || !isQuestionValuesUnique} appearance="primary" type="submit">
                  {t('Save')}
               </Button>
            </GridItem>
         </Grid>
      </Card>
   )
}
