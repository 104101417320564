import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '../../components/common/grid/Grid'
import { GridItem } from '../../components/common/grid/GridItem'
import { ControlledTextInput, TextInput } from '../../components/common/inputs/TextInput'
import { UseFormReturn } from 'react-hook-form'
import { DemandArea, Demand, DemandStatus, DemandSubType, DemandType, Origin } from '../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { ControlledDropDown } from '../../components/common/inputs/Dropdown'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { Button, MenuItem, Skeleton, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { addDemand, archiveDemand, deleteDemandAndResetLaw, getNewDemand, updateDemand } from '../../helpers/demandHelper'
import { DataContext } from '../../App'
import dayjs from 'dayjs'
import BasicAccordion from '../../components/common/accordion/BasicAccordion'
import { Add16Regular, DeleteRegular, MoreHorizontal20Regular, ArchiveRegular } from '@fluentui/react-icons'
import { Modal } from '../../components/common/modal/Modal'
import TextOutput from '../../components/common/inputs/TextOutput'
import { useMsal } from '@azure/msal-react'
import { ContextualMenu } from '../../components/common/menu/Menu'
import { useNavigate } from 'react-router-dom'
import { GetDemandById, GetDemands } from '../../api/demand'
import { handleMessage } from '../../helpers/stateHelper'
import { ControlledRTFInput } from '../../components/common/inputs/ControlledRTFInput'

const useStyles = makeStyles({
   midRigth: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
   marginRight: {
      marginRight: tokens.spacingHorizontalXL,
   },
   smallGap: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalS),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingHorizontalS),
      },
   },
   flexEnd: { display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
})

type SelectedDemandProps = {
   originDemand: Demand
   demandForm: UseFormReturn<Demand, any, undefined>
   grandParentId: string
   isDisabled: boolean
   setBread: () => Promise<void>
   hasQuestions?: boolean
}
const SelectedDemand = ({ originDemand, demandForm, grandParentId, isDisabled, setBread, hasQuestions }: SelectedDemandProps) => {
   const dataContext = useContext(DataContext)
   const { t } = useTranslation()
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   const { control, watch, reset, formState, setValue } = demandForm
   const { isDirty } = formState
   // const [open, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false)
   const classes = useStyles()
   const { accounts } = useMsal()
   const [openModal, setOpenModal] = useState<'archive' | 'delete' | 'undo'>()
   const [parentDemand, setParentDemand] = useState<Demand>(null)
   const previousAreaValue = originDemand?.area
   const onSaveClick = async () => {
      setIsSubmitting(true)
      setValue('modifiedBy', accounts[0].name)
      await updateDemand(watch(), grandParentId, dataContext, t, reset, setIsSubmitting)
      await setBread()
      if (watch('area') !== previousAreaValue) {
         const demands = await GetDemands()
         dataContext.setRootState((prev) => ({ ...prev, demands }))
      }
   }
   const onUndoClick = () => {
      reset(originDemand)
      setOpenModal(null)
   }
   useEffect(() => {
      if (originDemand.level !== 1) {
         ;(async () => {
            try {
               const parent = await GetDemandById(watch('parentId'))
               setParentDemand(parent)
            } catch (error) {
               handleMessage(dataContext.setRootState, 'error', 'Kunde inte hämta förälder', '')
            }
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   const canBeActivated = parentDemand?.status === DemandStatus.Archived
   const navigate = useNavigate()

   const modalButtonsDisabled = openModal === null
   return (
      <>
         <GridItem size="12/12">
            <BasicAccordion
               className={classes.midRigth}
               headerText={`${t('Demand')} - ${watch('designation')}`}
               actions={
                  <>
                     {watch('level') === 3 ? null : (
                        <Button
                           onClick={async () =>
                              await addDemand(getNewDemand(watch(), accounts[0].name), grandParentId, dataContext, t, setIsSubmitting, navigate)
                           }
                           size="small"
                           icon={<Add16Regular />}
                           appearance="transparent"
                           disabled={isDisabled}
                        >
                           {t('NewSubDemand')}
                        </Button>
                     )}
                     {watch('level') !== 1 && (
                        <ContextualMenu
                           menuTrigger={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" />}
                           menuItems={
                              <>
                                 <MenuItem icon={<ArchiveRegular fontSize={16} />} onClick={() => setOpenModal('archive')} disabled={canBeActivated}>
                                    {watch('status') === DemandStatus.Archived ? t('Activate') : t('Archive')}
                                 </MenuItem>

                                 <MenuItem
                                    icon={<DeleteRegular fontSize={16} />}
                                    onClick={() => setOpenModal('delete')}
                                    disabled={hasQuestions || originDemand.children.length > 0}
                                 >
                                    {t('Delete')}
                                 </MenuItem>
                              </>
                           }
                        />
                     )}
                  </>
               }
               children={
                  <Grid>
                     <GridItem size="7/12">
                        <ControlledRTFInput
                           name={'text'}
                           control={control}
                           label={t('Demand')}
                           isDisabled={isDisabled || isSubmitting}
                           required={true}
                           watchedValue={watch('text')}
                        />
                     </GridItem>
                     <GridItem className={classes.smallGap} size="2/12">
                        <ControlledDropDown
                           control={control}
                           name={'status'}
                           options={enumValuesToOptions(DemandStatus, t)
                              .filter((x) => x.key !== DemandStatus.SavedButNotSure)
                              .map((option) => ({
                                 ...option,
                                 disabled: option.key === DemandStatus.Archived,
                              }))}
                           label={t('Status')}
                           disabled={isDisabled || isSubmitting}
                        />
                        <ControlledDropDown
                           control={control}
                           name={'area'}
                           options={enumValuesToOptions(DemandArea, t)}
                           label={t('DemandArea')}
                           disabled={watch('level') !== 2 || isDisabled || isSubmitting}
                        />
                        <ControlledDropDown
                           control={control}
                           name={'type'}
                           options={enumValuesToOptions(DemandType, t)}
                           label={t('DemandType')}
                           disabled={true}
                        />
                        <ControlledDropDown
                           control={control}
                           name={'subType'}
                           options={enumValuesToOptions(DemandSubType, t)}
                           label={t('DemandSubType')}
                           disabled={isDisabled || isSubmitting}
                        />
                     </GridItem>
                     <GridItem className={classes.smallGap} size="2/12">
                        <ControlledTextInput control={control} name={'source'} label={t('DemandSource')} disabled={isDisabled || isSubmitting} />
                        <ControlledTextInput control={control} name={'urlText'} label={t('UrlText')} disabled={isDisabled || isSubmitting} />
                        <ControlledTextInput control={control} name={'url'} label={t('WebLink')} disabled={isDisabled || isSubmitting} />
                        <ControlledDropDown
                           control={control}
                           name={'origin'}
                           options={enumValuesToOptions(Origin, t)}
                           label={t('Origin')}
                           disabled={isDisabled || isSubmitting}
                        />
                        <ControlledTextInput control={control} name={'version'} label={t('Version')} disabled={isDisabled || isSubmitting} />
                     </GridItem>
                     <GridItem className={classes.smallGap} size="1/12">
                        <TextInput value={`D${watch('level')}`} label={t('DemandLevel')} disabled={true} />
                        {parentDemand ? <TextOutput value={watch('level') === 1 ? '' : parentDemand.designation} label={t('ParentDemand')} /> : <Skeleton />}
                        <TextOutput value={watch('modifiedBy')} label={t('ModifiedBy')} avatar={true} />
                        <TextOutput label={t('Modified')} value={dayjs(watch('modifiedDate')).format('YYYY-MM-DD hh:mm')} />
                     </GridItem>

                     <GridItem size="12/12" className={classes.flexEnd}>
                        <Button className={classes.marginRight} disabled={!isDirty || isSubmitting} onClick={() => setOpenModal('undo')}>
                           {t('Undo')}
                        </Button>
                        <Button appearance="primary" onClick={async () => onSaveClick()} disabled={!isDirty || isSubmitting}>
                           {t('Save')}
                        </Button>
                     </GridItem>
                  </Grid>
               }
            />
         </GridItem>

         <Modal
            open={openModal === 'undo'}
            title={t('UndoChanges')}
            actions={
               <>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                  <Button onClick={() => onUndoClick()} appearance="primary">
                     {t('Continue')}
                  </Button>
               </>
            }
            children={t('UndoDemandChanges')}
         />
         <Modal
            open={openModal === 'delete'}
            title={t('Warning')}
            actions={
               <>
                  <Button
                     onClick={async () =>
                        await deleteDemandAndResetLaw(watch(), dataContext, t, setIsSubmitting, setOpenModal, accounts[0].name, grandParentId, navigate)
                     }
                     appearance="primary"
                     disabled={isSubmitting}
                     style={{ backgroundColor: tokens.colorStatusDangerForeground1 }}
                  >
                     {t('Delete')}
                  </Button>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
               </>
            }
            children={t('DemandDeleteWarningText')}
         />
         <Modal
            open={openModal === 'archive'}
            title={t('Warning')}
            actions={
               <>
                  <Button onClick={() => setOpenModal(null)}>{t('Cancel')}</Button>
                  <Button
                     onClick={async () => await archiveDemand(watch(), dataContext, t, reset, setIsSubmitting, accounts[0].name, setOpenModal)}
                     appearance="primary"
                     disabled={isSubmitting || modalButtonsDisabled}
                  >
                     {t('Continue')}
                  </Button>
               </>
            }
            children={watch('status') === DemandStatus.Archived ? t('DemandActivateWarningText') : t('DemandArchiveWarningText')}
         />
      </>
   )
}

export default SelectedDemand
