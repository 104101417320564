import { Reorder } from 'framer-motion'
import { DemandRow } from './DemandRow'
import { useState } from 'react'
import { Demand } from '../../api/schemas/schema'
type DemandsProps = {
   demands: Demand[]
   loadingRows: string[]
   showButton: string
   expandAllDemands: boolean
   setShowButton: (value: React.SetStateAction<string>) => void
   toggleRowExpansion: (demandId: string) => void
}
export const Demands = ({ demands, loadingRows, showButton, expandAllDemands, setShowButton, toggleRowExpansion }: DemandsProps): JSX.Element => {
   const [dndParentDemands, setDndParentDemands] = useState<Demand[]>([])
   return (
      <Reorder.Group
         layoutScroll
         as="div"
         role="rowgroup"
         axis="y"
         values={demands}
         onReorder={(parentDemands) => {
            setDndParentDemands(parentDemands)
         }}
      >
         {demands.map((demand) => (
            <DemandRow
               demands={demands}
               dndParentDemands={dndParentDemands}
               demand={demand}
               loadingRows={loadingRows}
               showButton={showButton}
               expandAllDemands={expandAllDemands}
               setDndParentDemands={setDndParentDemands}
               setShowButton={setShowButton}
               toggleRowExpansion={toggleRowExpansion}
            />
         ))}
      </Reorder.Group>
   )
}
