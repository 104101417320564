import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import {
   Button,
   Dropdown,
   Field,
   Input,
   MenuItem,
   Popover,
   PopoverSurface,
   PopoverTrigger,
   Radio,
   RadioGroup,
   Text,
   tokens,
   Option,
} from '@fluentui/react-components'
import { UseFormReturn, useFieldArray } from 'react-hook-form'
import { ControlledTextInput } from '../common/inputs/TextInput'
import { Info16Regular, Dismiss20Regular, Add16Regular, Info16Filled, Delete20Regular, MoreHorizontal20Regular } from '@fluentui/react-icons'
import { QuestionFormValues } from '../../helpers/questionHelper'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { AnswerType, QuestionStatus } from '../../api/schemas/schema'
import { DataContext } from '../../App'
import { answerOptionConnection, choosenAnswerOptions } from '../../helpers/answerHelper'
import { ContextualMenu } from '../common/menu/Menu'

type AnswerOptionInQuestionProps = {
   questionForm: UseFormReturn<QuestionFormValues, any, undefined>
   isDisabled: boolean
}
const AnswerOptionInQuestion = ({ questionForm, isDisabled }: AnswerOptionInQuestionProps): JSX.Element => {
   const { watch, control, setValue } = questionForm
   const dataContext = useContext(DataContext)
   const { answerOptions } = dataContext.state
   const { fields, append, remove } = useFieldArray({
      control,
      name: 'selectedQuestion.answerOptions',
   })
   const { t } = useTranslation()
   const ownAnswersOptions = answerOptions.filter((x) => x.answerType === AnswerType.OwnAnswers)
   return (
      <section>
         <Field label={t('AnswerType')} required={watch('selectedQuestion.status') === QuestionStatus.Definite}>
            <RadioGroup
               required={watch('selectedQuestion.status') === QuestionStatus.Definite}
               onChange={(e, d) => choosenAnswerOptions(d.value, setValue, answerOptions, watch('selectedQuestion.id'))}
               style={{ display: 'flex', flexWrap: 'wrap' }}
               name={'answerType'}
               layout="horizontal"
               value={
                  watch(`selectedQuestion.answerType`) || watch(`selectedQuestion.answerType`) === 0 ? watch(`selectedQuestion.answerType`)?.toString() : ''
               }
               disabled={isDisabled}
            >
               {enumValuesToOptions(AnswerType, t).map((x) => (
                  <Radio value={x.key.toString()} label={x.text} />
               ))}
            </RadioGroup>
         </Field>
         <div style={{ display: 'flex', flexWrap: 'wrap', gap: tokens.spacingHorizontalXS, marginTop: tokens.spacingHorizontalS }}>
            {watch(`selectedQuestion.answerType`) || watch(`selectedQuestion.answerType`) === 0 ? (
               <>
                  <div style={{ width: '45%' }}>
                     <Text>{t('AnswerOption')}</Text> <Text style={{ color: tokens.colorPaletteRedForeground3 }}> *</Text>
                  </div>
                  <div style={{ width: '7%' }}>
                     <Text>{t('Info')}</Text>
                  </div>
                  <div style={{ width: '15%' }}>
                     <Text>{t('Value')}</Text> <Text style={{ color: tokens.colorPaletteRedForeground3 }}> *</Text>
                  </div>
                  <div style={{ width: '18%' }} />
               </>
            ) : null}

            {fields.map((item, i) => (
               <React.Fragment key={item.id}>
                  <div style={{ width: '45%' }}>
                     <ControlledTextInput control={control} name={`selectedQuestion.answerOptions.${i}.answerOption.text`} required={true} disabled={true} />
                  </div>
                  <div style={{ width: '7%' }}>
                     <Popover>
                        <PopoverTrigger disableButtonEnhancement>
                           <Button
                              icon={
                                 watch(`selectedQuestion.answerOptions.${i}.answerOption.infoLabel`) ? (
                                    <Info16Filled color={tokens.colorBrandBackground} />
                                 ) : (
                                    <Info16Regular />
                                 )
                              }
                              appearance="transparent"
                           />
                        </PopoverTrigger>
                        <PopoverSurface tabIndex={-1}>
                           <ControlledTextInput
                              control={control}
                              name={`selectedQuestion.answerOptions.${i}.answerOption.infoLabel`}
                              label={t('Info')}
                              multiline={true}
                              rows={3}
                              disabled={true}
                           />
                        </PopoverSurface>
                     </Popover>
                  </div>
                  <div style={{ width: '15%' }}>
                     <Input
                        aria-label={t('Value')}
                        type="number"
                        onChange={(e, d) => {
                           setValue(`selectedQuestion.answerOptions.${i}.answerOption.value`, Number(d.value), { shouldDirty: true })
                        }}
                        value={watch(`selectedQuestion.answerOptions.${i}.answerOption.value`)?.toString() ?? ''}
                        style={{ maxWidth: '100%' }}
                        required={true}
                        disabled={true}
                     />
                  </div>

                  <div style={{ width: '18%', display: 'flex', alignItems: 'center' }}>
                     {/**Nedan disablas tills vidare, sätts på paus. Tidigare var det disabeld={isDisabled} 17/9-2024 */}
                     {/* {watch(`selectedQuestion.answerOptions.${i}.answerOption.demandConnection`) ? (
                        !watch(`selectedQuestion.answerOptions.${i}.demandId`) ? (
                           <Popover>
                              <PopoverTrigger disableButtonEnhancement>
                                 <Button size="small" appearance="transparent" icon={<ClipboardLink16Regular />} disabled={true}>
                                    {t('DemandConnection')}
                                 </Button>
                              </PopoverTrigger>
                              <PopoverSurface tabIndex={-1}>
                                 <ControlledCombobox
                                    name={`selectedQuestion.answerOptions.${i}.demandId`}
                                    control={control}
                                    label={t('DemandConnection')}
                                    options={demands.map((x) => ({
                                       key: x.id,
                                       text: x.designation,
                                    }))}
                                    disabled={isDisabled}
                                 />
                              </PopoverSurface>
                           </Popover>
                        ) : (
                           <Text>
                              <DocumentBulletList16Regular />
                              {fakeIdGenerator(
                                 demands.find((x) => x.id === watch(`selectedQuestion.answerOptions.${i}.demandId`)),
                                 t,
                                 demands.find((x) => x.id === demands.find((x) => x.id === watch(`selectedQuestion.answerOptions.${i}.demandId`)).parentId)
                              )}
                           </Text>
                        )
                     ) : null} */}
                  </div>
                  {(watch(`selectedQuestion.answerOptions.${i}.answerOption.demandConnection`) ||
                     watch('selectedQuestion.answerType') === AnswerType.OwnAnswers) && (
                     <ContextualMenu
                        // Nedan disablas tills vidare, sätts på paus. Tidigare var det disabeld={isDisabled} 17/9-2024 */}
                        menuTrigger={<Button appearance="transparent" icon={<MoreHorizontal20Regular />} aria-label="More options" disabled={true} />}
                        menuItems={
                           <>
                              <MenuItem
                                 icon={<Dismiss20Regular />}
                                 onClick={() => setValue(`selectedQuestion.answerOptions.${i}.demandId`, null)}
                                 disabled={!watch(`selectedQuestion.answerOptions.${i}.demandId`) || isDisabled}
                              >
                                 {t('DeleteDemandConnection')}
                              </MenuItem>
                              {watch(`selectedQuestion.answerType`) === AnswerType.OwnAnswers && (
                                 <MenuItem icon={<Delete20Regular />} onClick={() => remove(i)} disabled={isDisabled}>
                                    {t('Delete')}
                                 </MenuItem>
                              )}
                           </>
                        }
                     />
                  )}
               </React.Fragment>
            ))}
            {watch(`selectedQuestion.answerType`) === AnswerType.OwnAnswers && (
               <div style={{ width: '100%' }}>
                  <Popover>
                     <PopoverTrigger disableButtonEnhancement>
                        <Button size="small" icon={<Add16Regular />} appearance="transparent" disabled={isDisabled}>
                           {t('AddAnswerOption')}
                        </Button>
                     </PopoverTrigger>
                     <PopoverSurface tabIndex={-1}>
                        <Dropdown placeholder={t('SelectAnAnswerOption')}>
                           {ownAnswersOptions.map((x) => (
                              <Option
                                 onClick={() => {
                                    append(
                                       answerOptionConnection(
                                          watch('selectedQuestion.id'),
                                          ownAnswersOptions.find((y) => y.id === x.id)
                                       )
                                    )
                                 }}
                                 key={x.id}
                                 disabled={watch('selectedQuestion.answerOptions').some((option) => option.answerOptionId === x.id) || isDisabled}
                              >
                                 {x.text}
                              </Option>
                           ))}
                        </Dropdown>
                     </PopoverSurface>
                  </Popover>
               </div>
            )}
         </div>
      </section>
   )
}

export default AnswerOptionInQuestion
