import React from 'react'
import { AnswerType, QuestionDTO, QuestionStatus } from '../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import StatusPill from '../../components/common/status/StatusPill'
import { statusBadgeColor } from '../../helpers/demandHelper'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { TableCell, TableRow, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'
import { RTFOutput } from '../../components/common/outputs/RTFOutput'

const useStyles = makeStyles({
   customTableRow: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
      '&:hover': {
         backgroundColor: tokens.colorPaletteMarigoldBackground2,
      },
   },
})

type QuestionRowProps = {
   question: QuestionDTO
}

export const QuestionRow = ({ question }: QuestionRowProps) => {
   const classes = useStyles()
   const { t } = useTranslation()

   return (
      <TableRow className={mergeClasses(classes.customTableRow)}>
         <TableCell>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${question.parentId}?question=${question.order}`}>
               {question.designation}
            </NavLink>
         </TableCell>
         <TableCell>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${question.parentId}?question=${question.order}`}>
               <RTFOutput text={question.text.length > 200 ? question.text.substring(0, 200) + '...' : question.text} fontSize={300} noMargin />
            </NavLink>
         </TableCell>
         <TableCell>{question.organisationSizes.map((x) => x.organisationSize.text).join(', ')}</TableCell>

         <TableCell>{question.organisationTypes.map((x) => x.organisationType.text).join(', ')}</TableCell>
         <TableCell>{question?.process?.process?.text && question?.process?.process?.text}</TableCell>
         <TableCell>{enumValuesToTranslate(AnswerType, t, question?.answerType)}</TableCell>
         <TableCell>
            <StatusPill color={statusBadgeColor(question.status)}>{enumValuesToTranslate(QuestionStatus, t, question.status)}</StatusPill>
         </TableCell>
      </TableRow>
   )
}
