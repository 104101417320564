import { TFunction } from 'i18next'
import { IColumn, IExtendedColumn } from '../interfaces/IColumn'

export const demandColumns = (t: TFunction<'translation', undefined>): IExtendedColumn[] => {
   return [
      { columnId: 1, label: '', width: '3%', minWidth: '30px' },
      { columnId: 2, label: t('DemandLevel'), width: '5%', minWidth: '90px' },
      { columnId: 3, label: t('ID'), width: '3%', minWidth: '30px' },
      { columnId: 4, label: t('Designation'), width: '5%', minWidth: '100px' },
      { columnId: 5, label: `${t('DemandText')}/${t('Question')}`, width: '30%', minWidth: '200px' },
      { columnId: 6, label: t('DemandType'), width: '10%', minWidth: '120px' },
      { columnId: 7, label: t('DemandSubType'), width: '10%', minWidth: '120px' },
      { columnId: 8, label: t('DemandSource'), width: '10%', minWidth: '120px' },
      { columnId: 9, label: t('DemandArea'), width: '10%', minWidth: '120px' },
      { columnId: 10, label: t('Status'), width: '5%', minWidth: '80px' },
   ]
}
export const questionColumns = (t: TFunction<'translation', undefined>): IColumn[] => {
   return [
      { columnId: 1, label: t('Designation'), width: 70 },
      { columnId: 2, label: t('Question'), width: 400 },
      { columnId: 3, label: t('OrganisationSize'), width: 180 },
      { columnId: 4, label: t('OrganisationType'), width: 180 },
      { columnId: 5, label: t('Process'), width: 120 },
      { columnId: 6, label: t('AnswerType'), width: 120 },
      { columnId: 7, label: t('Status'), width: 120 },
   ]
}
