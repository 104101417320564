import { Body1Strong, createTableColumn, TableColumnDefinition, Text } from '@fluentui/react-components'
import { ArrowDown16Regular } from '@fluentui/react-icons'
import { t } from 'i18next'
import { Activity } from '../api/schemas/schema'
import dayjs from 'dayjs'

export const getActivitiesColumns = (): TableColumnDefinition<Activity>[] => [
   createTableColumn<Activity>({
      columnId: 'lastModified',
      renderHeaderCell: () => (
         <>
            <Body1Strong>{t('Modified')}</Body1Strong>
            <ArrowDown16Regular />
         </>
      ),
      renderCell: (item) => <Text>{dayjs(item.modifiedDate).format('YYYY-MM-DD HH:mm')}</Text>,
   }),
   createTableColumn<Activity>({
      columnId: 'modifiedBy',
      renderHeaderCell: () => <Body1Strong>{t('ModifiedBy')}</Body1Strong>,
      renderCell: (item) => <Text>{item.modifiedBy}</Text>,
   }),
   createTableColumn<Activity>({
      columnId: 'activity',
      renderHeaderCell: () => <Body1Strong>{t('Changed')}</Body1Strong>,
      renderCell: (item) => <Text>{item.title}</Text>,
   }),
]
