import { Accordion, AccordionItem, AccordionHeader, Divider, AccordionPanel, Text } from '@fluentui/react-components'
import React from 'react'
import { Grid } from '../grid/Grid'
import { GridItem } from '../grid/GridItem'

type BasicAccordionProps = {
   headerText: string
   children: JSX.Element | JSX.Element[]
   className?: string
   actions?: JSX.Element | JSX.Element[]
   questionView?: boolean
   isOpen?: boolean
   onToggle?: () => void
}
const BasicAccordion = ({ headerText, children, className, actions, questionView, isOpen, onToggle }: BasicAccordionProps): JSX.Element => {
   return (
      <Accordion collapsible defaultOpenItems={['1']} openItems={questionView && (isOpen ? ['1'] : [])} onToggle={questionView && onToggle}>
         <AccordionItem value="1">
            <Grid>
               <GridItem size="8/12">
                  <AccordionHeader as="h2" expandIconPosition="start">
                     <Text weight="semibold">{headerText}</Text>
                  </AccordionHeader>
               </GridItem>
               <GridItem size="4/12" className={className}>
                  {actions ?? null}
               </GridItem>
            </Grid>
            <Divider appearance="brand" />
            <AccordionPanel>{children}</AccordionPanel>
         </AccordionItem>
      </Accordion>
   )
}

export default BasicAccordion
