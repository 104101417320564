import { Button, Tab, TabList, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { Page } from '../Page'
import { Title } from '../../components/common/texts/Title'
import { List } from '../../components/common/list/List'
import { getAnswerOptionColumns } from '../../helpers/lawHelper'
import { useContext } from 'react'
import { DataContext } from '../../App'
import { Add16Regular } from '@fluentui/react-icons'
import { NavLink } from 'react-router-dom'
import { Loader } from '../../components/common/spinner/Loader'

const useStyles = makeStyles({
   alignEnd: {
      alignSelf: 'end',
   },
   justifySelfEnd: {
      justifySelf: 'end',
   },
   paddingL: {
      ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),
   },
   maxWidth250: {
      maxWidth: '250px',
   },
   flex: { display: 'flex', flexDirection: 'column', ...shorthands.gap(tokens.spacingVerticalXL) },
   rowReverse: { display: 'flex', flexDirection: 'row-reverse' },
})
export const AnswerOptionList = () => {
   const { t } = useTranslation()
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { answerOptions } = dataContext.state
   return (
      <Page
         header={<Title as="h2">{t('SystemSettings')}</Title>}
         subheader={
            <TabList defaultSelectedValue="answerOptions">
               <Tab value="answerOptions">{t('AnswerOption')}</Tab>
            </TabList>
         }
      >
         <div className={classes.flex}>
            <div className={classes.rowReverse}>
               <Button size="small" icon={<Add16Regular />} appearance="transparent">
                  <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/system-settings/answer-options/new`}>
                     {t('CreateNewAnswerOption')}
                  </NavLink>
               </Button>
            </div>
            {answerOptions === null ? (
               <Loader />
            ) : (
               <List
                  pagination={false}
                  columns={getAnswerOptionColumns()}
                  items={answerOptions.sort((a, b) => a.order - b.order)}
                  title={t('AnswerOption')}
                  asCard
               />
            )}
         </div>
      </Page>
   )
}
