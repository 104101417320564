import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../App'
import { Api, Demand } from '../../api/schemas/schema'
import { Button, makeStyles, MessageBar, MessageBarBody, MessageBarTitle, shorthands, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { demandColumns } from '../../helpers/columnHelper'
import { demandExpansion } from '../../helpers/dndHelper'
import { Loader } from '../../components/common/spinner/Loader'
import { setLoading } from '../../helpers/stateHelper'
import { Modal } from '../../components/common/modal/Modal'
import { SearchableCombobox } from '../../components/common/inputs/SearchableCombobox'
import { onDemandMove } from '../../helpers/demandHelper'
import { Demands } from './Demands'

const useStyles = makeStyles({
   row: {
      display: 'flex',
      ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2),
      ...shorthands.padding(tokens.spacingVerticalS, 0),
      '&:hover': {
         backgroundColor: tokens.colorNeutralBackground1Hover,
      },
      '&:active': {
         backgroundColor: tokens.colorNeutralBackground1Pressed,
      },
   },
   colheader: {
      fontWeight: tokens.fontWeightBold,
   },
   modalHeight: {
      minHeight: '80px',
      overflow: 'visible',
   },
})

type CustomeDemandTableProps = {
   dialogOpen: boolean
   expandAllDemands: boolean
   moveDemandModalClosed: () => void
}

export const CustomDemandTable = ({ dialogOpen, expandAllDemands, moveDemandModalClosed }: CustomeDemandTableProps) => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const [showButton, setShowButton] = useState<string>('')
   const [loadingRows, setLoadingRows] = useState<string[]>([])
   const { demands, selectedTab, isLoading } = dataContext.state
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   const { t } = useTranslation()
   const [archivedDemands, setArchivedDemands] = useState<Demand[]>(null)
   const [targetDemand, setTargetDemand] = useState<Demand>()

   const toggleRowExpansion = (demandId: string): void => {
      demandExpansion(dataContext, demandId, setLoadingRows)
   }

   const onModalClose = () => {
      moveDemandModalClosed()
      setTargetDemand(undefined)
   }

   useEffect(() => {
      if (demands) {
         ;(async () => {
            setLoading(dataContext.setRootState, true)
            switch (selectedTab) {
               case 'archivedDemands':
                  const archivedDemandsResult = (await apiInstance.api.demandArchiveList()).data
                  setArchivedDemands(archivedDemandsResult)
                  // eslint-disable-next-line array-callback-return
                  archivedDemandsResult?.map((archivedDemand) => {
                     toggleRowExpansion(archivedDemand.id)
                  })
                  //setItems(await expandDemands(dataContext, archivedDemandsResult))

                  setLoading(dataContext.setRootState, false)
                  break
               case 'activeDemands':
               default:
                  setArchivedDemands(null)
                  setLoading(dataContext.setRootState, false)
                  break
            }
         })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedTab])

   return (
      <div role="table">
         <div role="rowgroup">
            <div role="row" className={classes.row}>
               {demandColumns(t).map((col) => (
                  <span key={col.columnId} role="columnheader" className={classes.colheader} style={{ width: col.width, minWidth: col.minWidth }}>
                     {col.label}
                  </span>
               ))}
            </div>
         </div>
         {isLoading ? (
            <Loader />
         ) : selectedTab === 'archivedDemands' && archivedDemands && archivedDemands.length === 0 ? (
            <MessageBar>
               <MessageBarBody>
                  <MessageBarTitle>{t('NoArchivedDemands')}</MessageBarTitle>
               </MessageBarBody>
            </MessageBar>
         ) : (
            <>
               <Demands
                  demands={archivedDemands ?? demands}
                  loadingRows={loadingRows}
                  showButton={showButton}
                  expandAllDemands={expandAllDemands}
                  setShowButton={setShowButton}
                  toggleRowExpansion={toggleRowExpansion}
               />
               <Modal
                  open={dialogOpen}
                  title={t('MoveDemand')}
                  actions={
                     <>
                        <Button onClick={onModalClose} disabled={isLoading}>
                           {t('Cancel')}
                        </Button>
                        <Button
                           disabled={!targetDemand || isLoading}
                           appearance="primary"
                           onClick={() => {
                              onDemandMove(dataContext, targetDemand, onModalClose, t)
                           }}
                        >
                           {t('Move')}
                        </Button>
                     </>
                  }
                  contentClassname={classes.modalHeight}
                  children={<SearchableCombobox targetDemand={targetDemand} placeholder={t('DemandModalSearchParams')} setTargetDemand={setTargetDemand} />}
               />
            </>
         )}
      </div>
   )
}
