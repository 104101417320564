import React, { useEffect, useRef, useState } from 'react'
import {
   Button,
   Field,
   Input,
   Popover,
   PopoverSurface,
   PositioningImperativeRef,
   Toolbar,
   ToolbarButton,
   ToolbarGroup,
   makeStyles,
   tokens,
} from '@fluentui/react-components'
import { useCurrentEditor } from '@tiptap/react'
import {
   Link16Filled,
   Link16Regular,
   TextBold16Filled,
   TextBulletList16Filled,
   TextBulletList16Regular,
   TextItalic16Filled,
   TextItalic16Regular,
   TextNumberListLtr16Filled,
   TextNumberListLtr16Regular,
   TextUnderline16Filled,
   TextUnderline16Regular,
} from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
   menubar: {
      borderBottom: `${tokens.strokeWidthThin} solid ${tokens.colorNeutralStroke1}`,
      gap: tokens.spacingHorizontalXL,
   },
   popoverSurface: {
      display: 'flex',
      gap: tokens.spacingHorizontalXL,
   },
   active: {
      backgroundColor: tokens.colorNeutralBackground1Selected,
   },
   group: {
      display: 'flex',
      gap: tokens.spacingHorizontalXXS,
   },
})
export const MenuBar = () => {
   const { editor } = useCurrentEditor()
   const [linkPopoverOpen, setLinkPopoverOpen] = useState<boolean>(false)
   const [url, setUrl] = useState<string>('')
   const { t } = useTranslation()
   const classes = useStyles()

   const buttonRef = useRef<HTMLButtonElement>(null)
   const positioningRef = useRef<PositioningImperativeRef>(null)
   const handleInsertLink = () => {
      if (url) {
         editor.chain().focus().setLink({ href: url }).run()
         setUrl('')
         setLinkPopoverOpen(false)
      }
   }
   useEffect(() => {
      if (buttonRef.current) {
         positioningRef.current?.setTarget(buttonRef.current)
      }
   }, [buttonRef, positioningRef])
   if (!editor) {
      return null
   }

   return (
      <Toolbar className={classes.menubar}>
         <ToolbarGroup className={classes.group}>
            <ToolbarButton
               className={editor.isActive('bold') ? classes.active : ''}
               icon={editor.isActive('bold') ? <TextBold16Filled /> : <TextBold16Filled />}
               aria-label="bold"
               onClick={() => editor.chain().focus().toggleBold().run()}
               disabled={!editor.can().chain().focus().toggleBold().run()}
            />
            <ToolbarButton
               className={editor.isActive('underline') ? classes.active : ''}
               icon={editor.isActive('underline') ? <TextUnderline16Filled /> : <TextUnderline16Regular />}
               aria-label="underline"
               onClick={() => editor.chain().focus().toggleUnderline().run()}
               disabled={!editor.can().chain().focus().toggleUnderline().run()}
            />
            <ToolbarButton
               className={editor.isActive('italic') ? classes.active : ''}
               icon={editor.isActive('italic') ? <TextItalic16Filled /> : <TextItalic16Regular />}
               aria-label="italic"
               onClick={() => editor.chain().focus().toggleItalic().run()}
               disabled={!editor.can().chain().focus().toggleItalic().run()}
            />
            <ToolbarButton
               className={editor.isActive('link') ? classes.active : ''}
               icon={editor.isActive('link') ? <Link16Filled /> : <Link16Regular />}
               aria-label="link"
               ref={buttonRef}
               onClick={() => {
                  const { from, to } = editor.state.selection
                  const selectedText = editor.state.doc.textBetween(from, to, '')
                  if (to - from !== 0 && selectedText) {
                     setUrl(selectedText)
                     setLinkPopoverOpen(true)
                  }
               }}
            />
            <Popover open={linkPopoverOpen} onOpenChange={(e, data) => setLinkPopoverOpen(data.open)} positioning={{ positioningRef }}>
               <PopoverSurface className={classes.popoverSurface}>
                  <Field label={t('EnterURL') + ':'} orientation="horizontal">
                     <Input type="text" value={url} onChange={(e) => setUrl(e.target.value)} />
                  </Field>
                  <Button appearance="subtle" onClick={handleInsertLink}>
                     {t('Save')}
                  </Button>
               </PopoverSurface>
            </Popover>
         </ToolbarGroup>
         <ToolbarGroup className={classes.group}>
            <ToolbarButton
               className={editor.isActive('bulletList') ? classes.active : ''}
               icon={editor.isActive('bulletList') ? <TextBulletList16Filled /> : <TextBulletList16Regular />}
               aria-label="bullet list"
               onClick={() => editor.chain().focus().toggleBulletList().run()}
            />
            <ToolbarButton
               className={editor.isActive('orderedList') ? classes.active : ''}
               icon={editor.isActive('orderedList') ? <TextNumberListLtr16Filled /> : <TextNumberListLtr16Regular />}
               aria-label="numbered/ordered list"
               onClick={() => editor.chain().focus().toggleOrderedList().run()}
            />
         </ToolbarGroup>
      </Toolbar>
   )
}
