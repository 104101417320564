import { Button, SelectTabData, SelectTabEvent, Switch, Tab, TabList, makeStyles, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { Page } from '../Page'
import { Title } from '../../components/common/texts/Title'
import { CustomDemandTable } from './CustomDemandTable'
import { DataContext } from '../../App'
import { QuestionTable } from '../Question/QuestionTable'
import { excelExporter, ExportStatus } from '../../helpers/excelHelper'
import { DialogExportingList } from './DialogExportingList'
import { ArchiveRegular, ArrowExportFilled, BookQuestionMarkRegular, BookRegular, ArrowForwardRegular, CubeQuickRegular } from '@fluentui/react-icons'
import { onDemandMoveClick } from '../../helpers/demandHelper'
import React, { useContext, useState } from 'react'
import { useBoolean } from '@fluentui/react-hooks'
import { setLoading } from '../../helpers/stateHelper'
import { toggleExpandAllDemands } from '../../helpers/dndHelper'
const useStyles = makeStyles({
   flexColumn: {
      display: 'flex',
      flexDirection: 'column',
   },
   stickyWrapper: {
      position: 'sticky',
      top: 0,
      zIndex: 1000,
      backgroundColor: tokens.colorNeutralBackground1,
      padding: '10px',
   },
   listNavContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '20px',
      marginLeft: '10px',
   },
   flexRow: {
      display: 'flex',
   },
   iconMargin: {
      marginRight: tokens.spacingHorizontalSNudge,
   },
})

export const ComplianceOfDemands = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const [exportingStatus, setExportingStatus] = useState<ExportStatus>(null)
   const { selectedTab, moveDemands, selectedDemands, demands } = dataContext.state
   const [expandAllDemands, setExpandDemands] = useState<boolean>(false)
   const [dialogOpen, { setTrue: moveDemandModalOpen, setFalse: moveDemandModalClosed }] = useBoolean(false)
   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      dataContext.setRootState((prev) => ({ ...prev, selectedTab: data.value.toString() }))
   }
   const handleSwitchChange = () => {
      const isExpanded = !expandAllDemands
      setExpandDemands(isExpanded)
      toggleExpandAllDemands(dataContext, isExpanded)
      setLoading(dataContext.setRootState, true)
   }
   return (
      <Page
         header={<Title as="h2">{t('DemandQuestionList')}</Title>}
         subheader={
            <div className={classes.flexColumn}>
               <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                  <Tab value="activeDemands" icon={<BookRegular />}>
                     {t('ActiveDemands')}
                  </Tab>
                  <Tab value="activeQuestions" icon={<BookQuestionMarkRegular />}>
                     {t('ActiveQuestions')}
                  </Tab>
                  <Tab value="archivedDemands" icon={<ArchiveRegular />}>
                     {t('ArchivedDemands')}
                  </Tab>
               </TabList>

               <div className={classes.listNavContainer}>
                  <div className={classes.flexRow}>
                     {/* <SearchField placeHolder={t('Search')} /> */}
                     {selectedTab === 'activeDemands' && (
                        <>
                           {moveDemands && (
                              <Button
                                 appearance="subtle"
                                 icon={<ArrowForwardRegular />}
                                 disabled={!selectedDemands.length || (selectedDemands && !selectedDemands.find((x) => x.level === 2 || x.level === 3))}
                                 onClick={moveDemandModalOpen}
                              >
                                 {t('MoveTo')}
                              </Button>
                           )}
                           <Button appearance="subtle" icon={<CubeQuickRegular />} onClick={() => onDemandMoveClick(dataContext)}>
                              {t(`${moveDemands ? 'AbortFastMove' : 'FastMoveDemand'}`)}
                           </Button>
                        </>
                     )}
                     <Button
                        disabled={exportingStatus !== null}
                        onClick={async () => await excelExporter(dataContext, t, setExportingStatus)}
                        appearance="subtle"
                        icon={<ArrowExportFilled />}
                     >
                        {t('ExportDemandsAndQuestionsToExcel')}
                     </Button>
                  </div>
                  <Switch
                     label={t(expandAllDemands ? 'MiniMizeAll' : 'ExpandAll')}
                     disabled={!demands}
                     checked={expandAllDemands}
                     onChange={handleSwitchChange}
                  />
               </div>
               <DialogExportingList exportingStatus={exportingStatus} />
            </div>
         }
         stickySubHeader
      >
         {selectedTab === 'activeQuestions' ? (
            <QuestionTable />
         ) : (
            <CustomDemandTable dialogOpen={dialogOpen} expandAllDemands={expandAllDemands} moveDemandModalClosed={moveDemandModalClosed} />
         )}
      </Page>
   )
}
